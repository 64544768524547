// eslint-disable-next-line no-restricted-imports
import { usePathname } from 'next/navigation'
import { useTranslations } from 'next-intl'
import { useMemo } from 'react'

import type { ButtonPillProps } from '@fortum/elemental-ui'
import { ButtonPill } from '@fortum/elemental-ui'

import { usePurchaseFlowNavigation } from '@/open-web/hooks/usePurchaseFlowNavigation'
import type { CampaignConfigurationEntry } from '@/shared/contentful/types'
import { routes } from '@/shared/routes'
import type {
  EnrichedContractProduct,
  EnrichedContractTemplate,
} from '@/shared/services/campaignDataResolver'
import { useGlobalStore } from '@/shared/store/provider'
import type {
  CustomerType,
  EntryPointType,
  SelectedAddons,
} from '@/shared/store/slices/selectedContractSlice'
import { filterOnlySpecialAddons, filterOutSpecialAddons } from '@/shared/utils/addonsUtils'
import { logError } from '@/shared/utils/error'
import {
  getPriceElements,
  mapContractAndAddons,
  useDeliveryInfo,
} from '@/shared/utils/gtm/gtmHelper'
import { contractItemsEvent } from '@/shared/utils/sendGTMEvent'

import { validateCustomerType } from '../Price/utils'
import { useSelectedAddonsTariffNos } from '../PriceBreakdown/hooks/useSelectedAddonsTariffNos'

export type PurchaseButtonProps = ButtonPillProps & {
  contractTemplate: EnrichedContractTemplate
  contractProduct: EnrichedContractProduct
  campaignConfiguration: CampaignConfigurationEntry
  invert?: boolean
  /**
   *  If true, the upgrade flow will be skipped and the user will be taken directly to the checkout
   */
  isSkippingUpgradeFlow?: boolean
  /**
   * Extra action to be executed when the button is clicked
   */
  onPurchaseButtonClick?: VoidFunction
  /**
   * Optional information about purchase entry point (source page). Used for navigation logic.
   */
  entryPointType?: EntryPointType
}

export const PurchaseButton: React.FC<PurchaseButtonProps> = ({
  contractTemplate,
  invert = false,
  contractProduct,
  campaignConfiguration,
  isSkippingUpgradeFlow,
  onPurchaseButtonClick,
  entryPointType = 'default',
  ...props
}) => {
  const t = useTranslations('purchaseFlow')
  const isSweden = process.env.NEXT_PUBLIC_COUNTRY === 'SE'
  const isFinland = process.env.NEXT_PUBLIC_COUNTRY === 'FI'

  // That is correct that `next` usePathname is used, cuz we need current pathname not the route
  const pathname = usePathname()
  const {
    selectContract,
    setSelectedAddons,
    setContractProduct: setSelectedContractProduct,
    setCampaignConfiguration,
    enterprise: enterpriseSelectedContract,
    private: privateSelectedContract,
    setEntryPointDetails,
  } = useGlobalStore((store) => store.selectedContract)
  const {
    delivery: { deliveryAddressType },
  } = useGlobalStore((store) => store.checkoutFlow)
  const navigateNextPage = usePurchaseFlowNavigation()
  const { selectedAddons, contractTemplate: selectedContractTemplate } =
    contractTemplate.customerType === 'PRIVATE'
      ? privateSelectedContract
      : enterpriseSelectedContract

  const { itemListName, customerType } = useGlobalStore((store) => store.gtm)

  const { deliveryStartDate, estimatedConsumption, deliveryArea } = useDeliveryInfo()

  const { selectedAddonsTariffNos } = useSelectedAddonsTariffNos({ contractTemplate })

  const hasAvailableAddons = useMemo(() => {
    return filterOutSpecialAddons(contractTemplate.availableAddons)?.length > 0
  }, [contractTemplate.availableAddons])

  const onButtonClick = () => {
    const getPreselectedAddons = () => {
      //Only addons that exist on selected contract and might be preselected
      const preselectedAddons = filterOnlySpecialAddons(contractTemplate.availableAddons)

      return Object.entries(selectedAddons).reduce<SelectedAddons>((acc, curr) => {
        if (preselectedAddons.some((addon) => addon.tariffNo === curr[1].value)) {
          return { ...acc, [curr[0]]: curr[1] }
        }
        return acc
      }, {})
    }

    const { error: contractError } = validateCustomerType(contractTemplate)

    if (contractError) {
      throw logError(contractError)
    }

    const contractCustomerType = contractTemplate.customerType.toLocaleLowerCase() as CustomerType

    selectContract(contractTemplate, contractCustomerType)

    setCampaignConfiguration(campaignConfiguration, contractCustomerType)

    //Filter contractTemplates to only those from same campaign
    setSelectedContractProduct(
      {
        ...contractProduct,
        contractTemplates: contractProduct.contractTemplates.filter(
          (ct) => ct.campaignId === campaignConfiguration.campaignId,
        ),
      },
      contractCustomerType,
    )

    //Reset selected addons when contract is changed
    if (
      contractTemplate.templateNo !== selectedContractTemplate?.templateNo ||
      contractTemplate.campaignId !== selectedContractTemplate?.campaignId
    ) {
      setSelectedAddons(getPreselectedAddons(), contractCustomerType)
    }

    if (onPurchaseButtonClick) {
      onPurchaseButtonClick()
    }

    // Set additional information about the entry point for the purchase flow
    setEntryPointDetails(
      entryPointType,
      pathname,
      contractTemplate.customerType.toLocaleLowerCase() as CustomerType,
    )

    const hasValidDeliveryAreaAndEstimatedConsumption = estimatedConsumption && deliveryArea

    const isAbleToSkipUpgradeFlowInSweden =
      isSweden && !hasAvailableAddons && hasValidDeliveryAreaAndEstimatedConsumption

    const isAbleToSkipUpgradeFlowInFinland =
      (isFinland && !hasAvailableAddons) ||
      (isSkippingUpgradeFlow && hasValidDeliveryAreaAndEstimatedConsumption)

    if (isAbleToSkipUpgradeFlowInSweden || isAbleToSkipUpgradeFlowInFinland) {
      navigateNextPage(routes.CHECKOUT, routes.ENTERPRISE_CHECKOUT)
    } else {
      navigateNextPage(routes.UPGRADE_FLOW, routes.ENTERPRISE_UPGRADE_FLOW)
    }

    if (contractTemplate) {
      const priceElements = getPriceElements(
        contractTemplate,
        deliveryArea,
        estimatedConsumption,
        selectedAddonsTariffNos,
        deliveryStartDate,
      )
      const items = mapContractAndAddons(
        contractTemplate,
        [],
        priceElements,
        deliveryAddressType,
        customerType,
      )

      contractItemsEvent(
        'select_item',
        {
          item_list_id: `${contractProduct?.cfData.title} - ${itemListName}`,
          item_list_name: itemListName,
        },
        items,
      )
    }
  }

  return (
    <ButtonPill
      data-testid="order-contract"
      onClick={onButtonClick}
      status={invert ? 'campaign' : undefined}
      {...props}
    >
      {`${t('order')} ${contractProduct?.cfData.title}`}
    </ButtonPill>
  )
}
