import { DateTime } from 'luxon'

import type { CostDetails } from '@/open-web/services/calculators/calculateContractTemplateCost'
import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import { formatNumber } from '@/open-web/utils/priceFormat'
import { browserEnvs } from '@/shared/envs'
import type { PriceDetails } from '@/shared/graphql/queries/queryTypes'
import type {
  ContractDiscountElement,
  TariffElement,
} from '@/shared/graphql/schema/commonBackend/graphql'
import { getDefaultLocale, getFullLocale } from '@/shared/locale'
import type { Theme } from '@/shared/providers/theme'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'
import { SalesCampaignMisconfigurationError } from '@/shared/utils/errorClasses'

import type { PriceProps } from './types'

export const defineTheme = (colors: Theme['colors'], theme: PriceProps['theme'] = 'light') => {
  switch (theme) {
    case 'light':
      return {
        title: colors.textPrimary,
        price: colors.textPrimary,
        crossedPrice: colors.textSecondary,
        detailedPrice: colors.textPrimary,
        noPriceInfo: colors.textPositive,
        duration: colors.textPositive,
      }

    case 'dark':
      return {
        title: colors.textLightOnDark,
        price: colors.textLightOnDark,
        crossedPrice: colors.textLightOnDark,
        detailedPrice: colors.textLightOnDarkAccent,
        noPriceInfo: colors.textLightOnDarkAccent,
        duration: colors.textLightOnDarkAccent,
      }
  }
}

export const validateCustomerType = (contractTemplate: EnrichedContractTemplate) => {
  if (
    contractTemplate.customerType !== 'ENTERPRISE' &&
    contractTemplate.customerType !== 'PRIVATE'
  ) {
    const error = new SalesCampaignMisconfigurationError(
      `Contract template templateNo: ${contractTemplate.templateNo} has UNKNOWN customerType. Contract has bad configuration. Can not be sold!`,
      {
        templateNo: contractTemplate.templateNo,
        tariffNo: contractTemplate.tariffNo,
      },
    )

    return { error }
  }

  return { error: null }
}

export const formatFinnishPrice = (
  price: number | string | null | undefined,
  unit?: string,
): string => {
  if (price == null) {
    return '--'
  }

  const formattedPrice = Number(price).toLocaleString('fi-FI', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return unit ? `${formattedPrice} ${unit}` : formattedPrice
}

export const getDiscountedPrice = (
  price: number,
  discountElement: ContractDiscountElement | undefined,
  customerType: string,
): number => {
  const discountElementPrice = discountElement?.prices
    ? getPriceBaseOnCustomerType(discountElement.prices[0], customerType)
    : 0
  return discountElement ? price + (discountElementPrice || 0) : price
}

export const getCurrentPrice = (prices?: PriceDetails[] | null) => {
  const today = DateTime.now()
  return prices?.find(
    (price) =>
      DateTime.fromISO(price.fromDate) <= today &&
      price.toDate &&
      DateTime.fromISO(price.toDate) > today,
  )
}

export const getFuturePrice = (prices?: PriceDetails[] | null) => {
  const today = DateTime.now()
  return prices?.find((price) => DateTime.fromISO(price.fromDate) > today) || null
}

export const getActiveOrFutureMonthlyFee = (monthlyFees?: TariffElement): PriceDetails | null => {
  if (!monthlyFees) {
    return null
  }
  const futureMonthlyFee = getFuturePrice(monthlyFees.prices ?? [])
  const currentMonthlyFee = getCurrentPrice(monthlyFees.prices ?? [])

  return futureMonthlyFee || currentMonthlyFee!
}

export const calculateDiscountPercent = (
  priceWithoutDiscountInclVat: number | null,
  priceWithDiscountInclVat: number | null,
): number | string | null => {
  if (priceWithoutDiscountInclVat == null || priceWithDiscountInclVat == null) {
    return null
  }

  // Full discount
  if (priceWithDiscountInclVat === 0) {
    return 100
  }

  const discountPercent =
    ((priceWithoutDiscountInclVat - priceWithDiscountInclVat) / priceWithoutDiscountInclVat) * 100

  const country = browserEnvs.NEXT_PUBLIC_COUNTRY

  const defaultLocale = getDefaultLocale(country)

  const locale = getFullLocale(country, defaultLocale)

  return formatNumber(discountPercent, locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
}

export type PriceDetailsArgs = {
  isMonthlyFeeDiscounted: boolean
  isEnergyDiscounted: boolean
  monthlyFee: CostDetails | null
  monthlyFeeWithDiscount: CostDetails | null
  energy: CostDetails | null
  energyWithDiscount: CostDetails | null
}

/**
 * Retrieves the prices with and without discounts based on the provided arguments.
 */
export const getPriceDetails = ({
  isMonthlyFeeDiscounted,
  isEnergyDiscounted,
  monthlyFee,
  monthlyFeeWithDiscount,
  energy,
  energyWithDiscount,
}: PriceDetailsArgs) => {
  const getPriceInclVatOrNull = (fee: CostDetails | null): number | null => {
    if (!fee) {
      return null
    }
    return fee.priceInclVat
  }

  if (isMonthlyFeeDiscounted) {
    return {
      priceWithoutDiscount: getPriceInclVatOrNull(monthlyFee),
      priceWithDiscount: getPriceInclVatOrNull(monthlyFeeWithDiscount),
    }
  }

  if (isEnergyDiscounted) {
    return {
      priceWithoutDiscount: getPriceInclVatOrNull(energy),
      priceWithDiscount: getPriceInclVatOrNull(energyWithDiscount),
    }
  }

  return {
    priceWithoutDiscount: null,
    priceWithDiscount: null,
  }
}
