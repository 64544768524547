import type { FontSize } from '@fortum/elemental-ui'
import { Box, ContentText, spacing } from '@fortum/elemental-ui'

import { Divider } from '@/shared/components/Divider'
import { useTheme } from '@/shared/hooks/useTheme'
import { PriceSection } from '@/shared/sections/PriceSection'

export type PriceRowProps = {
  label: string
  price: string
  priceWithoutDiscounts?: string
  description?: string
  isDividerHidden?: boolean
  priceItemFontSize?: FontSize
  priceStrikeThroughSize?: FontSize
  testId?: number
}

export const PriceRow = ({
  label,
  price,
  priceWithoutDiscounts,
  description,
  isDividerHidden,
  priceItemFontSize,
  priceStrikeThroughSize,
  testId,
}: PriceRowProps) => {
  const theme = useTheme()

  return (
    <>
      <Box
        data-testid={`price-row-${testId}`}
        display="flex"
        justifyContent="space-between"
        gap={spacing.xxxxs}
      >
        <ContentText flex="2" style={{ hyphens: 'auto' }}>
          {label}
        </ContentText>
        <PriceSection
          priceItemFontSize={priceItemFontSize}
          priceStrikeThroughSize={priceStrikeThroughSize}
          priceItems={[
            { price, originalPrice: priceWithoutDiscounts, originalPriceDescription: description },
          ]}
          colors={{
            textSecondary: theme.colors.textSecondary,
            textPositive: theme.colors.textPositive,
          }}
          boxProps={{ flex: '3' }}
        />
      </Box>
      {!isDividerHidden && (
        <Divider
          color={{ line: theme.colors.borderPrimary }}
          variant="dashed"
          verticalSpacing="xxxxs"
        />
      )}
    </>
  )
}
